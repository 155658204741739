
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { GroupDTO } from '@/data/dto/group.dto';
import ModalFormHeader from '@/components/ModalFormHeader.vue';
import SearchBox from '@/components/SearchBox.vue'; 

@Component({
  components: {
    ModalFormHeader,
    SearchBox,
  }
})
export default class ManageUserGroups extends Vue {
  @Prop(String) private currentUser?: string;

  showGroupsList = false;

  //todo:: melhorar nomenclatura
  allGroups: GroupDTO[] = [];
  userGroups: GroupDTO[] = [];
  listedGroups: GroupDTO[] = [];
  groupsToAdd: GroupDTO[] = [];

  get userGroupsUUID(): Array<string | undefined> {
    return this.userGroups.map(g => g.uuid || g.UUID);
  }
  // hide groups already associated to user
  get filteredGroups(): GroupDTO[] {
    return this.allGroups
      .filter(g => !(this.userGroupsUUID.includes(g.uuid) || this.userGroupsUUID.includes(g.UUID)));
  }

  async getGroups(): Promise<void> {
    try {
      const { groups } = await this.$store.dispatch('getAllGroups');
      this.allGroups = groups;
    } catch (e) {
      this.allGroups = [];
    }
  }

  async getUserGroups(): Promise<void> {    
    try {
      const groups = await this.$store.dispatch('getOneUser', this.currentUser);
      this.userGroups = groups;
    } catch (e) {
      this.userGroups = [];
    }
  }

  async removeUserFromGroup(group:GroupDTO, index: number): Promise<void> {
    try {
      await this.$store.dispatch('removeUserFromApplicationGroup', {
        email: this.currentUser,
        groupUUID: group.UUID
      });
      this.$store.commit('showAlert', { message: 'Regra removida com sucesso!', type: 'success'});
      this.userGroups.splice(index, 1);
    } catch (e) { return; }   
  }

  async addUserToGroup(): Promise<void> {
    try {
      const newGroupsUuid = this.groupsToAdd.map(group => group.uuid);
      await this.$store.dispatch('addUserToApplicationGroup', {
        email: this.currentUser,
        groups: newGroupsUuid
      });
      this.$store.commit('showAlert', { message: 'Regras adicionadas com sucesso!', type: 'success'});
      this.userGroups.push(...this.groupsToAdd);
      this.groupsToAdd.splice(0);
      this.showGroupsList = false;
    } catch (e) { return; }   
  }
  
  onSearchFinished(result:GroupDTO[]): void {
    this.listedGroups = result;
    this.showGroupsList = true;
  }

  onSearchReseted(result:GroupDTO[]): void {
    this.listedGroups = result;
    this.showGroupsList = false;
  }  

  cloneUser(): void {
    console.log('clonado filho')
    this.$emit('userCloned', this.userGroups);
  }

  cancel(): void { 
    this.$emit('canceled');
  }

  @Watch('allGroups')
  onGroupsChanged(groups: GroupDTO[]): void {
    this.listedGroups = groups;
  }  

  async mounted(): Promise<void> {
    await this.getUserGroups();
    await this.getGroups();
  }
}
