export default class ValidationUtil {
  static isValidEmail(text: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(text);
  }

  static isValidFullName(text: string): boolean {
    //at least two digits separated by a white space  
    const re = /\S+\s\S+/i;
    return re.test(text);
  }

  static isNumber(value: any): boolean {
    return typeof(value) === 'number';
  }

  static listContainsAtLeastOneItem(array: any[]): boolean {
    return Array.isArray(array) && array.length > 0;
  }
  
  static hasRequiredField(field: string): boolean {
    return !!field;
  }

  static dateIsAfterOrEqualMinimumDate(date1: string, limit: string): boolean {
    return date1 >= limit;
  }
}
