
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import { GroupDTO } from '@/data/dto/group.dto';
import ModalFormHeader from '@/components/ModalFormHeader.vue';
import SearchBox from '@/components/SearchBox.vue';
import VUtil from '@/utils/validation.util';

type UserData = { name: string; email: string; groups: string[]; }

@Component({
  components: {
    ModalFormHeader,
    SearchBox
  }
})
export default class CreateUser extends Mixins(FormValidationMixin) {
  @Prop({ default: () => [] }) groupsToClone!: GroupDTO[];

  user: UserData = {
    name:'',
    email:'',
    groups: []
  };
  
  allGroups: GroupDTO[] = [];
  listedGroups: GroupDTO[] = [];

  formValidations: IFormValidations = {
    name: () => VUtil.isValidFullName(this.user.name),
    email: () => VUtil.isValidEmail(this.user.email),
  }; 

  onSearchFinished(result:GroupDTO[]): void {
    this.listedGroups = result;
  }

  onSearchReseted(): void {
    this.listedGroups = this.allGroups;
  }

  async getGroups(): Promise<void> {
    try {
      const { groups } = await this.$store.dispatch('getAllGroups');
      this.allGroups = groups;
    } catch (e) {
      this.allGroups = [];
    }
  }

  async createUser(): Promise<void> {
    try {
      await this.$store.dispatch('createUser', this.user);
      this.$store.commit('showAlert', { message: 'Usuário cadastrado com sucesso!', type: 'success'});
      this.$emit('userCreated', this.user);      
    } catch (e) { return; }
  }

  selectClonedGroups(): void {
    //ajustar após correção de uuid/UUID
    this.user.groups = this.groupsToClone.map(g => g.uuid || g.UUID || '');
  }

  onSubmit(): void {
    if (!this.validateForm()) return;
    this.createUser();
  }

  cancel(): void {
    this.$emit('canceled'); 
  }

  @Watch('allGroups')
  onGroupsChanged(groups: GroupDTO[]): void {
    this.listedGroups = groups;
  }

  async mounted(): Promise<void> {
    await this.getGroups();
    this.selectClonedGroups();    
  }
}
