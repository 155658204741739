
import { Component, Watch, Mixins } from 'vue-property-decorator';
import RouteTitle from '@/components/RouteTitle.vue';
import { UserDTO } from '@/data/dto/user.dto';
import DatetimeUtil from '@/utils/datetime.util';
import CreateUser from '@/views/Admin/Users/CreateUser.vue';
import ManageUserGroups from '@/views/Admin/Users/ManageUserGroups.vue';
import SearchBox from '@/components/SearchBox.vue';
import { GroupDTO } from '@/data/dto/group.dto';
import Pagination from '@/mixins/pagination.mixin';

type CreateUserData = { name: string; email: string; groups: string[]; }

@Component({
  components: {
    RouteTitle,
    CreateUser,
    ManageUserGroups,
    SearchBox
  },
})
export default class Users extends Mixins(Pagination) {
  modalIsVisible = false;
  userToEdit: string | null = null;

  allUsers: UserDTO[] = [];
  filteredUsers: UserDTO[] = [];
  clonedUserGroups: GroupDTO[] = [];

  get usersInCurrentPage(): UserDTO[] {
    return this.itensInCurrentPage(this.filteredUsers);
  }

  userStatusLabel(status: boolean): string {
    return status ? 'Ativo' : 'Inativo'
  }

  shortDateTime(timestamp?: number): string {
    return DatetimeUtil.shortDateTime(timestamp, true);
  }

  onSearchFinished(result:UserDTO[]): void {
    this.filteredUsers = result;
  }

  onSearchReseted(): void {
    this.filteredUsers = this.allUsers;
  }

  editUser(user:UserDTO): void {
    this.setUserToEdit(user.email);
    this.showModal();
  }

  setUserToEdit(email: string):void {
    this.userToEdit = email;
  }

  unsetUserToEdit(): void {
    this.userToEdit = null;
  }

  parseCreateUserDataToUser(user: CreateUserData): UserDTO {
    return {
      email: user.email,
      name: user.name,
      active: true,
      created_at: DatetimeUtil.unixTime(),
      updated_at: DatetimeUtil.unixTime(),
      total: user.groups.length.toString(),
    };
  }

  onUserCreated(user: CreateUserData): void {
    this.allUsers.unshift(this.parseCreateUserDataToUser(user));
    this.hideModal();
  }

  onUserCloned(groups: GroupDTO[]): void {
    console.log('clonado pai', groups)
    this.clonedUserGroups = groups;
    this.userToEdit = null;
  }

  onUserCreationCanceled(): void {
    this.clonedUserGroups = [];
    this.hideModal();
  }

  async getUsers(): Promise<void> {
    try {
      const { users } = await this.$store.dispatch('getAllUsers');
      this.allUsers = users;
    } catch (e) {
      this.allUsers = [];
    }
  }

  async deleteUser(user: UserDTO): Promise<void> {
    try {
      await this.$store.dispatch('deleteUser', user);
      this.$store.commit('showAlert', { message: 'Usuário removido com sucesso!', type: 'success'});
      const deletedItemIndex: number = this.allUsers.findIndex((u) => u.email === user.email);
      this.allUsers.splice(deletedItemIndex, 1);
    } catch (e) { return; }
  }

  async simulateUser(user: UserDTO): Promise<void> {
    try {
      await this.$store.dispatch('simulateUser', user.email);
      await this.$router.push({ name: 'home' });
      this.$router.go(0);
    } catch (e) { return; }
  }

  async updateUserStatus(user: UserDTO): Promise<void> {
    try {
      const updatedUser: UserDTO = {...user, active: !user.active};
      await this.$store.dispatch('updateUserStatus', updatedUser);
      this.$store.commit('showAlert', { message: 'Status do usuário atualizado com sucesso!', type: 'success'});
      user.active = updatedUser.active;
    } catch (e) { return; }
  }

  showModal(): void {
    this.modalIsVisible = true;
  }

  hideModal(): void {
    this.modalIsVisible = false;
  }

  @Watch('allUsers')
  onUsersChanged(users: UserDTO[]): void {
    this.filteredUsers = users;
  }

  mounted(): void {
    this.getUsers();
    this.$root.$on('bv::modal::hide', () => {
      this.unsetUserToEdit();
    })
  }

}
